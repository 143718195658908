import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Head from "../Base/Head";

export default function Events() {
  const [showGif, setShowGif] = useState(false);
  const [gifPosition, setGifPosition] = useState("");
  const [gifRandomCache, setGifRandomCache] = useState(12345);

  const head = {
    title: "CHEDDAR",
    description: "",
    url: "https://getcheddar.lol",
    image: "https://i.imgur.com/1wrsXZf.png",
  };

  // function getRandomInt(min, max) {
  //   return Math.floor(Math.random() * (max - min + 1)) + min;
  // }

  // useEffect(() => {
  //   function triggerGif() {
  //     // generate random position - must be different than previous
  //     const positions = ["top", "bottom", "left", "right"];
  //     let newPosition = positions[getRandomInt(0, 3)];
  //     while (newPosition === gifPosition) {
  //       newPosition = positions[getRandomInt(0, 3)];
  //     }
  //     // show gif
  //     setGifPosition(newPosition);
  //     setGifRandomCache(getRandomInt(0, 1000000));
  //     setShowGif(true);
  //     // console.log(`setShowGif: true, position: ${newPosition}`);
  //     // hide gif after 5 seconds
  //     setTimeout(() => {
  //       setShowGif(false);
  //       // console.log(`setShowGif: false`);
  //       // wait for x time then show gif again
  //       setTimeout(() => {
  //         triggerGif();
  //       }, getRandomInt(3000, 9000));
  //     }, 5000);
  //   }
  //   triggerGif();
  // }, []);

  return (
    <>
      <Head head={head} />
      <div className="emu-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/images/emu-shadow.png"}
          className="img-fluid emu-main-img"
        ></img>
        {/* <div className="header">CHEDDAR</div> */}
        <div className="emu-text-anim">
          <img
            src={process.env.PUBLIC_URL + "/images/cheddar-cheese.png"}
            className="img-fluid emu-text-img"
            alt="CHEDDAR"
          />
        </div>
        <div className="btns-container">
          <a
            href="https://app.uniswap.org/explore/tokens/base/0x29c72afbc1eb5bff48762d3d62ecf2418125e1ba"
            className="main-btn"
          >
            <span>Get that $CHEDDAR</span>
          </a>
          <a
            href="https://dextools.io/app/en/base/pair-explorer/0x4052c56bf44af558cf91ab4a81e89f31cd508448?t=1712854258266"
            className="main-btn"
          >
            <span>Dextools</span>
          </a>
          {/* <a href="#" className="btnxxx">
            <span>Telegram</span>
          </a> */}
          <a href="https://twitter.com/GetThatCHEDDAR" className="main-btn">
            <span>X / TWITTER</span>
          </a>
        </div>
        <div className="token-addr">
          <b>Token Address:</b>
          <span className="mob-only">
            <br></br>
          </span>
          <a
            href="https://basescan.org/address/0x29C72aFBC1eB5bfF48762d3d62Ecf2418125E1BA"
            target="_blank"
          >
            <span className="addr">
              0x29C72aFBC1eB5bfF48762d3d62Ecf2418125E1BA
            </span>
          </a>
        </div>
        {showGif && (
          <img
            src={`${process.env.PUBLIC_URL}/images/head.gif?v=${gifRandomCache}`}
            alt="gif"
            className={`emu-head-anim ${gifPosition}`}
          />
        )}
      </div>
    </>
  );
}
