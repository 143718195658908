import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { Analytics } from "@vercel/analytics/react";
import Header from "./Base/Header";
import Footer from "./Base/Footer";
import Cheddar from "./Cheddar/Cheddar";
import { setCookie } from "./utils";
import ApiService from "./services/ApiService";

export default function App() {
  const homepage = process.env.REACT_APP_HOMEPAGE || "";
  const [changeHead, setChangeHead] = useState(false);
  const [urlParams, setUrlParams] = useState(null);

  console.log("HOMEPAGE", homepage);
  console.log(`window.location.pathname`, window.location.pathname);
  console.log(`urlParams`, urlParams);

  useEffect(() => {
    try {
      // Get the current URLSearchParams object from the window.location
      const params = new URLSearchParams(window.location.search);

      // Create an empty object to store the parsed parameters
      const parsedParams = {};

      // Loop through all the URL parameters and store them in the parsedParams object
      for (const [key, value] of params.entries()) {
        parsedParams[key] = value;
      }

      // set cookies
      const userId = parsedParams["u"];
      if (userId) {
        // console.log(`setting userId cookie`, userId);
        // setCookie("userId", userId);
        // setUserId(userId);
      }

      // Set the parsed parameters in the state
      setUrlParams(parsedParams);
    } catch (error) {
      console.log(`error`, error);
    }
  }, []);

  useEffect(() => {
    async function getUserInfo() {
      try {
        const api = new ApiService();
        const response = await api.get(`landmark/contacts/getContact/`);
        console.log(`getUserInfo() - landmark/contacts/getContact/`, response);
        if (response && response.contactId) {
          //setUser(response);
        } else {
          throw `Couldn't get user from userId`;
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    }
    if (true) {
      //getUserInfo();
    }
  }, []);

  // Render the component based on the value of `HOMEPAGE` env variable
  const renderHomepage = () => {
    return <Cheddar />;
  };
  let shouldShowHeader = true;
  let shouldShowFooter = true;
  let headerProps = {
    sticky: true,
    transparent: false,
    cssClass: "position-fixed",
  };

  const isOnPage = (page) => {
    if (
      window.location.pathname.includes(page.toLowerCase()) ||
      homepage === page.toLowerCase()
    ) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Toaster
        containerStyle={{
          top: 100,
        }}
        toastOptions={{
          style: {
            background: "#175d8c",
            color: "#fff",
          },
        }}
      />
      <div className={"main"}>
        <Routes>
          <Route path="" element={renderHomepage()} />
        </Routes>
      </div>
      <Analytics />
    </>
  );
}
